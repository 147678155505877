
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarMaintainAdd } from '@/types/carManage'

@Component({
  name: 'AddMaintain'
})
export default class extends Vue {
  private info: CarMaintainAdd = {
    projectId: '',
    machineId: '',
    repairTime: '',
    repairAddress: '',
    repairReason: '',
    repairPrice: '',
    typeName: '',
    brand: '',
    model: ''

  }

  private disabledMachineName = true
  private submitShow = false
  private machineList: CarMaintainAdd[] = []
  private rules = {
    projectId: [{ required: true, message: '请选择项目名称', trigger: 'change' }],
    machineId: [{ required: true, message: '请选择车牌号', trigger: 'change' }],
    repairAddress: [
      { required: true, message: '请输入维修地点', trigger: 'blur' }
    ],
    repairTime: [
      { required: true, message: '请选择维修时间', trigger: 'change' }
    ]
  }

  get repairId () {
    return this.$route.params.id as string || ''
  }

  get carTypeList () {
    return this.$store.state.carTypeList
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    if (this.repairId) {
      this.getDetail(this.repairId)
    }
  }

  getDetail (repairId: string) {
    this.$axios
      .get(this.$apis.carManage.selectVehicleRepairById, { repairId })
      .then((res) => {
        this.info = res
        this.getMachineList()
      })
  }

  // 车牌号
  getMachineList () {
    this.$axios
      .get(this.$apis.carManage.selectMachineList, {
        projectId: this.info.projectId
      })
      .then((res) => {
        this.machineList = res || []
      })
  }

  // 项目改变
  changeProject (value: string) {
    this.info.machineId = ''
    this.info.typeName = ''
    this.info.brand = ''
    this.info.model = ''

    this.disabledMachineName = !value
    value && this.getMachineList()
  }

  changeMachine (value: string) {
    const res = this.machineList.find((item) => item.machineId === value)
    if (res) {
      this.info.typeName = res.typeName
      this.info.brand = res.brand
      this.info.model = res.model
    }
  }

  saveForm () {
    ;(this.$refs.form as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const urls = this.$route.params.id
          ? this.$apis.carManage.updateVehicleRepair
          : this.$apis.carManage.insertVehicleRepair
        // 不需要传这三个参数
        delete this.info.typeName
        delete this.info.brand
        delete this.info.model
        this.$axios
          .post(urls, this.info)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.back()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
